import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Typography } from '@mui/material'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

interface props {
    open: boolean
    title: string
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: ReactJSXElement | ReactJSXElement[]
}

export const ModalStock: React.FC<props> = (props) => {
    const handleClose = () => props.setOpen(false)

    return (
        <div>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {props.title}
                    </Typography>
                    {props.children}
                </Box>
            </Modal>
        </div>
    )
}
