import * as React from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import fetch from 'node-fetch'
import { useEffect, useState } from 'react'

export interface StockOptionType {
    name: string
    symbol: string
}

interface props {
    ticker: string
    // setTicker(ticker: string): React.Dispatch<React.SetStateAction<string>>
    setTicker: React.Dispatch<React.SetStateAction<string>>
}

export const SearchAutocompleteView: React.FC<props> = (props) => {
    const [data, setData] = useState<[StockOptionType] | []>([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://alg.stepin.app/stock/list')
            const data: [StockOptionType] = await response.json()
            data.push({ name: 'All available stocks', symbol: 'ALL' })
            data.sort((n1, n2) => {
                if (n1.name > n2.name) {
                    return 1
                }
                if (n1.name < n2.name) {
                    return -1
                }
                return 0
            })
            setData(data)
        }

        fetchData().catch(console.error)
    }, [props.ticker])

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(event, value) => {
                    const ticker = getTicker(value)
                    props.setTicker(ticker)
                }}
                options={data.map(
                    (option: StockOptionType) =>
                        `${option.name} - (${option.symbol})`
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search input"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
            />
        </Stack>
    )
}

function getTicker(option: string) {
    var trimOption = option.substring(
        option.indexOf('(') + 1,
        option.lastIndexOf(')')
    )
    return trimOption
}
