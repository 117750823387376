import React from 'react'
import { DashboardView } from './view/DashboardView'

function App() {
    return (
        <div className="App">
            {/* <StockCardAllListView /> */}
            <DashboardView />
        </div>
    )
}

export default App
