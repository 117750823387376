import React, { useEffect, useState } from 'react'
import { StockCard } from '../components/StockCard/StockCard'
import { IndicatorRSI } from '../components/IndicatorRSI/IndicatorRSI'
import { IndicatorBBands } from '../components/IndicatorBBands/IndicatorBBands'
import Grid from '@mui/material/Grid'
import { StockSingle } from './typings'
import { BuyButtonModal } from '../components/BuyButtonModal/BuyButtonModal'

interface props {
    ticker: string
}

export const StockCardSingleAPIView: React.FC<props> = (props) => {
    const [stock, setStock] = useState<StockSingle | null>(null)

    useEffect(() => {
        if (props.ticker !== '') {
            const fetchData = async () => {
                const response = await fetch(
                    `https://alg.stepin.app/stock/name/${props.ticker}`
                )
                const stock = await response.json()
                setStock(stock)
            }

            fetchData().catch(console.error)
        }
    }, [props.ticker])

    if (stock !== null) {
        const rsi_daily = stock.indicators.rsi.rsi_daily!
        const rsi_week = stock.indicators.rsi.rsi_week
        const rsi_month = stock.indicators.rsi.rsi_month

        const bband_daily = stock.indicators.bbands.bbands_daily
        const bband_week = stock.indicators.bbands.bbands_week
        const bbands_month = stock.indicators.bbands.bbands_month

        return (
            <StockCard
                ticker={stock.ticker}
                name={'Some description'}
                type={'Common Stock'}
                price={stock.price}
                score={stock.score}
            >
                <Grid container spacing={2}>
                    <Grid item xs>
                        <IndicatorRSI
                            title="RSI Daily"
                            pos={rsi_daily.pos}
                            score={rsi_daily.score}
                            color={rsi_daily.color}
                            date={rsi_daily.date}
                        />
                    </Grid>
                    <Grid item xs>
                        <IndicatorRSI
                            title="RSI Weekly"
                            pos={rsi_week.pos}
                            score={rsi_week.score}
                            color={rsi_week.color}
                            date={rsi_week.date}
                        />
                    </Grid>
                    <Grid item xs>
                        <IndicatorRSI
                            title="RSI Monthly"
                            pos={rsi_month.pos}
                            score={rsi_month.score}
                            color={rsi_month.color}
                            date={rsi_month.date}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <IndicatorBBands
                            title={'bbands daily'}
                            away_from_low={bband_daily.away_from_low}
                            pos={bband_daily.pos}
                            diff_to_high={bband_daily.diff_to_high}
                            diff_to_med={bband_daily.diff_to_med}
                            diff_to_low={bband_daily.diff_to_low}
                            date={bband_daily.date}
                            color={bband_daily.color}
                            score={bband_daily.score}
                        />
                    </Grid>
                    <Grid item xs>
                        <IndicatorBBands
                            title={'bbands weekly'}
                            away_from_low={bband_week.away_from_low}
                            pos={bband_week.pos}
                            diff_to_high={bband_week.diff_to_high}
                            diff_to_med={bband_week.diff_to_med}
                            diff_to_low={bband_week.diff_to_low}
                            date={bband_week.date}
                            color={bband_week.color}
                            score={bband_week.score}
                        />
                    </Grid>
                    <Grid item xs>
                        <IndicatorBBands
                            title={'bbands monthly'}
                            away_from_low={bbands_month.away_from_low}
                            pos={bbands_month.pos}
                            diff_to_high={bbands_month.diff_to_high}
                            diff_to_med={bbands_month.diff_to_med}
                            diff_to_low={bbands_month.diff_to_low}
                            date={bbands_month.date}
                            color={bbands_month.color}
                            score={bbands_month.score}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <BuyButtonModal stock={stock} />
                    </Grid>
                </Grid>
            </StockCard>
        )
    } else {
        return <div>No data available yet..</div>
    }
}
