import { Alert, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BuyRowType } from '../../view/typings'

interface props {
    ticker: string
    price: string
}
export const ModalBuyContent: React.FC<props> = (props) => {
    const [stock, setStock] = useState<[BuyRowType] | []>([])
    const currentPrice = Number(props.price)

    useEffect(() => {
        if (props.ticker !== '') {
            const fetchData = async () => {
                const response = await fetch(
                    `https://alg.stepin.app/stock/select/buy/${props.ticker}`
                )
                const stock = await response.json()
                setStock(stock)
            }

            fetchData().catch(console.error)
        }
    }, [props.ticker])

    if (stock.length == 0) {
        return <>No data available</>
    } else {
        return (
            <>
                {stock.map((stock, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {stock.who}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Alert severity="info">
                                $
                                {(
                                    Math.round(Number(stock.price) * 100) / 100
                                ).toFixed(2)}
                            </Alert>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {stock.date}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Alert
                                severity={
                                    Number(stock.price) > currentPrice
                                        ? 'success'
                                        : 'error'
                                }
                            >
                                {Number(stock.price) > currentPrice
                                    ? `Winning this trade by ${(Math.round(Number(stock.price) * 100 / currentPrice - 100)).toFixed(2)} %`
                                    : `Losing this trade by ${(Math.round(Number(stock.price) * 100 / currentPrice - 100)).toFixed(2)} %`}
                            </Alert>
                        </Grid>
                    </React.Fragment>
                ))}
            </>
        )
    }
}
