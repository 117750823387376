import * as React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { ModalStock } from '../ModalStock/ModalStock'

import Typography from '@mui/material/Typography'
import { StockSingle } from '../../view/typings'
import { ModalBuyContent } from '../ModalBuyContent/ModalBuyContent'
import { Alert, Divider, Grid, TextField } from '@mui/material'
import { Snack } from '../Snack/Snack'

interface props {
    stock: StockSingle
}

export const BuyButtonModal: React.FC<props> = (props) => {
    const [open, setOpen] = React.useState(false)
    const [name, setName] = React.useState('')

    //Snack
    const [openSnack, setOpenSnack] = React.useState(false)
    const [snackMessage, setSnackMsg] = React.useState('')

    const handleOpen = () => setOpen(true)
    const stock = props.stock

    const buyAsset = async () => {
        const body = {
            state: JSON.stringify(props.stock),
            who: name,
            ticker: props.stock.ticker,
            price: props.stock.price,
            date: new Date().toLocaleDateString(),
        }
        await fetch(`https://alg.stepin.app/stock/buy`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        })
    }

    return (
        <Stack spacing={2} direction="row" style={{ paddingTop: '10px' }}>
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                severity="info"
                message={snackMessage}
            />
            <ModalStock
                open={open}
                setOpen={setOpen}
                title={`Buying ${stock.ticker}`}
            >
                <>
                    <Alert severity="warning">
                        $
                        {(Math.round(Number(stock.price) * 100) / 100).toFixed(
                            2
                        )}
                    </Alert>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Current score: {stock.score}
                    </Typography>
                    <Divider style={{ padding: '10px' }} />
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Your name"
                        variant="outlined"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Divider style={{ padding: '10px' }} />
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={() => {
                            if (name === '') {
                                setSnackMsg('You need to input a name')
                                setOpenSnack(true)
                            } else {
                                setSnackMsg(`You bought ${stock.ticker}`)
                                buyAsset()
                            }
                        }}
                    >
                        Buy
                    </Button>
                    <Divider style={{ padding: '10px' }} />
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                who
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                price
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                date
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                change
                            </Typography>
                        </Grid>
                        <ModalBuyContent
                            ticker={stock.ticker}
                            price={props.stock.price}
                        />
                    </Grid>
                </>
            </ModalStock>
            <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handleOpen}
            >
                Buy
            </Button>
        </Stack>
    )
}
