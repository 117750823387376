import React, { useEffect, useState } from 'react'
import { StockSingle } from './typings'
import { StockCardSingleLocalView } from './StockCardSingleLocalView'
import { Grid } from '@mui/material'

export const StockCardAllListView: React.FC<{}> = () => {
    const [stocks, setStocks] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`https://alg.stepin.app/stock/all`)
            const stocks = await response.json()
            setStocks(stocks)
        }
        fetchData().catch(console.error)
    }, [])

    if (stocks.length > 0) {
        return (
            <>
                {stocks.map((item: StockSingle, index: number) => (
                    <StockCardSingleLocalView key={index} stock={item} />
                ))}
            </>
        )
    } else {
        return <div>No data available yet for all assets..</div>
    }
}
