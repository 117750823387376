import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type StockType = {
    ticker: string
    name: string
    type: string
    price: string
    score: number
    children: ReactJSXElement | ReactJSXElement[]
}

export const StockCard: React.FC<StockType> = (props) => {
    let borderColor = '1px solid red'
    if (props.score <= 6) {
        borderColor = '1px solid green'
    }
    return (
        <Card sx={{ minWidth: 275, border: borderColor }}>
            <CardContent>
                <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                >
                    {props.type}
                </Typography>
                <Typography variant="h4" component="div">
                    {props.ticker}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {props.name}
                </Typography>
                <Typography variant="body2">
                    Current price: {props.price}
                </Typography>
                <Typography variant="body2">
                    Current score: {props.score}
                </Typography>
                {props.children}
            </CardContent>
        </Card>
    )
}
