import * as React from 'react'
import Stack from '@mui/material/Stack'

import { SearchAutocompleteView } from './SearchAutocompleteView'
import { useEffect, useState } from 'react'
import { StockCardSingleAPIView } from './StockCardSingleAPIView'
import { StockCardAllListView } from './StockCardAllListView'
import { BuysView } from './BuysView'

export const DashboardView: React.FC<{}> = () => {
    const [ticker, setTicker] = useState<string>('')

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <SearchAutocompleteView ticker={ticker} setTicker={setTicker} />
            <BuysView />    
            {ticker === 'ALL' ? (
                <StockCardAllListView />
            ) : (
                <StockCardSingleAPIView ticker={ticker} />
            )}
        </Stack>
    )
}
