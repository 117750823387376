import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
)

type BBandsProps = {
    title: string
    away_from_low: number
    pos: number
    diff_to_high: number
    diff_to_med: number
    diff_to_low: number
    date: string
    color: string
    score: number
}

export const IndicatorBBands: React.FC<BBandsProps> = (props) => {
    return (
        <div>
            <span style={{ padding: 2 }} />
            <Card sx={{ minWidth: 275, backgroundColor: props.color }}>
                <CardContent>
                    <Stack direction="row" spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    {' '}
                                    {props.title}{' '}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                Score: {props.score}
                            </Grid>
                            <Grid item xs={12}>
                                Away from bottom:{' '}
                                {(
                                    Math.round(props.away_from_low * 100) / 100
                                ).toFixed(2)}
                            </Grid>
                            <Grid item xs={12}>
                                Chart Pos: {props.pos}
                            </Grid>
                            <Grid item xs={12}>
                                Price diff from High band:{' '}
                                {(
                                    Math.round(props.diff_to_high * 100) / 100
                                ).toFixed(2)}
                            </Grid>
                            <Grid item xs={12}>
                                Price diff from Med band:{' '}
                                {(
                                    Math.round(props.diff_to_med * 100) / 100
                                ).toFixed(2)}
                            </Grid>
                            <Grid item xs={12}>
                                Price diff from Low band:{' '}
                                {(
                                    Math.round(props.diff_to_low * 100) / 100
                                ).toFixed(2)}
                            </Grid>
                            <Grid item xs={12}>
                                Snapshot: {props.date}
                            </Grid>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </div>
    )
}
