import * as React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import Alert from '@mui/material/Alert'

interface props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    severity: AlertColor
    message: string
}
export const Snack: React.FC<props> = (props) => {
    const handleClick = () => {
        props.setOpen(true)
    }

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        props.setOpen(false)
    }

    return (
        <Snackbar
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={props.severity}
                sx={{ width: '100%' }}
            >
                {props.message}
            </Alert>
        </Snackbar>
    )
}
