import { Alert, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ModalStock } from '../components/ModalStock/ModalStock'
import { DbBuys } from './typings'

export const BuysView: React.FC<{}> = () => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const [assets, setAssets] = useState<DbBuys | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(
                `https://alg.stepin.app/stock/select/all`
            )
            const assets = await response.json()
            setAssets(assets)
        }

        fetchData().catch(console.error)
    }, [])

    return <>
        <ModalStock
            open={open}
            setOpen={setOpen}
            title={`Current buys`}
        >
            <Grid container spacing={2} style={{height: '500px', overflow: 'auto'}}>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Buyer
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Stock
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Purchased
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Date
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Current
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Status
                    </Typography>
                </Grid>
                {assets?.db.map((stock, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={2}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {stock.who}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Alert severity="warning">
                                {stock.ticker}
                            </Alert>
                        </Grid>
                        <Grid item xs={2}>
                            <Alert severity="info">
                                $
                                {(
                                    Math.round(Number(stock.price) * 100) / 100
                                ).toFixed(2)}
                            </Alert>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {stock.date}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Alert severity="info">
                                $
                                {(
                                    Math.round(Number(assets.assets[stock.ticker]) * 100) / 100
                                ).toFixed(2)}
                            </Alert>
                        </Grid>
                        <Grid item xs={2}>
                            <Alert
                                severity={
                                    Number(stock.price) > Number(assets.assets[stock.ticker])
                                        ? 'error'
                                        : 'success'
                                }
                            >
                                {Number(stock.price) > Number(assets.assets[stock.ticker])
                                    ? `Losing this trade by ${(Math.round(Number(stock.price) * 100 / Number(assets.assets[stock.ticker]) - 100)).toFixed(2)} %`
                                    : `Winning this trade by ${(Math.round(Number(stock.price) * 100 / Number(assets.assets[stock.ticker]) - 100)).toFixed(2)} %`}
                            </Alert>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </ModalStock>
        <Button variant="contained" onClick={handleOpen}>See current buys</Button>
    </>
}
